import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from 'date-fns/locale/nl';

export const renderShoppingCart = (
    pricingAgreements,
    cart,
    handleUpdateQuantity,
    handleRemoveFromCart,
    totalPrice,
    selectedDeliveryDate,
    handleDeliveryDateChange,
    setOrderConfirmation,
    ) => {
        const handleOrderConfirmationClick = () => {
            if (!selectedDeliveryDate) {
                alert("Selecteer eerst een bezorgdatum");
            } else {
                setOrderConfirmation(true);
                window.scrollTo(0, 0);
            }
        }
    return (
        <div className="table-container">
            <h3 className="title">Winkelwagen</h3>
            <table>
                <thead>
                    <tr>
                        <th className="bordered-cell">Productnaam</th>
                        <th>Hoeveelheid</th>
                        {/* <th>Prijs</th> */}
                        {/* <th>Bijzonderheden</th> */}
                    </tr>
                </thead>
                <tbody>
                    {cart?.map((item) => {
                        const product = pricingAgreements.find((agreement) => agreement.ProductID === item.productId);
                        return (
                            <tr key={item.productId}>
                                <td>{product.ProductName}</td>
                                <td>
                                    <button className="smallbuttonShoppingcart"  onClick={() => handleUpdateQuantity(item.productId, item.quantity - 1)}>-</button>
                                    {item.quantity}
                                    <button className="smallbuttonShoppingcart"  onClick={() => handleUpdateQuantity(item.productId, item.quantity + 1)}>+</button>
                                </td>
                                {/* <td>€ {(item.quantity * product.Price).toFixed(2)}</td> */}
                                <td>
                                    <button className="button" onClick={() => handleRemoveFromCart(item.productId)}>Verwijderen</button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {/* <p>Totaal: € {totalPrice.toFixed(2)}</p> */}
            <div>
                <label>
                    Gewenste bezorgdatum:
                    <DatePicker
                        selected={selectedDeliveryDate}
                        onChange={handleDeliveryDateChange}
                        minDate={new Date(new Date().setDate(new Date().getDate()+1))} // Prevent selection of past dates and the next day
                        filterDate={(date) => {
                            const now = new Date();
                            const threePM = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 15, 0, 0);
                            const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
                        
                            const dayOfWeekTomorrow = tomorrow.getDay();
                            const isWeekendTomorrow = (dayOfWeekTomorrow === 0 || dayOfWeekTomorrow === 6);
                        
                            let nextMonday = null;
                            if (dayOfWeekTomorrow === 6 && now > threePM) { // Saturday
                                nextMonday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 3); // Monday
                            } else if (dayOfWeekTomorrow === 0) { // Sunday
                                nextMonday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 2); // Monday
                            } else if (dayOfWeekTomorrow === 1) { // Monday
                                nextMonday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1); // Monday
                            }
                            // else if (dayOfWeekTomorrow === 5) { // Friday
                            //     nextMonday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 4); // Monday
                            // }
                        
                            const allowTomorrow = now < threePM && !isWeekendTomorrow;
                            const blockWeekends = (date.getDay() === 0 || date.getDay() === 6);
                            const blockNextMonday = (nextMonday && date.getTime() === nextMonday.getTime());
                        
                            return (date.getTime() === tomorrow.getTime() ? allowTomorrow : true) && !blockWeekends && !blockNextMonday;
                        }}
                        placeholderText="Selecteer een datum"
                        locale={nl}
                        dateFormat="dd/MM/yyyy"
                        onChangeRaw={(e) => e.preventDefault()}
                    />
                </label>
            </div>
            <button className="button" onClick={handleOrderConfirmationClick}>Naar orderoverzicht</button>
        </div>
    );
};