import React, {useState} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { changePassword } from "../api/salesAPI";
import LHlogo from "./LH.png";

const RenderPasswordChange = () => {
    const { customerName } = useParams();
    const navigate = useNavigate();
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [message, setMessage] = useState("");
    const [isChangingPassword, setIsChangingPassword] = useState(false);

    const validatePassword = (password) => {
        const minLength = 8;
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return password.length >= minLength && passwordRegex.test(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation
        if (!currentPassword || !newPassword || !confirmNewPassword) {
            setMessage("Vul alle velden in");
            return;
        }

        if (!validatePassword(newPassword)) {
            setMessage("Het nieuwe wachtwoord moet minstens 8 karakters lang zijn, minstens 1 hoofdletter, 1 kleine letter, 1 cijfer en 1 speciaal karakter bevatten");
            return;
        }

        if (newPassword !== confirmNewPassword) {
            setMessage("Nieuwe wachtwoorden komen niet overeen");
            return;
        }

        try {
            const response = await changePassword({ customerName, currentPassword, newPassword });
            setIsChangingPassword(true);
            if (response.data.message === "Password changed successfully") {
                setMessage("Wachtwoord gewijzigd, als u op terug klikt kunt u opnieuw inloggen met het nieuwe wachtwoord");

                // clear the form
                setCurrentPassword("");
                setNewPassword("");
                setConfirmNewPassword("");
                setIsChangingPassword(false);
                // Optional: redirect the user after a successful password change
                // navigate('/some-path'); // Redirect to another page if needed
            } else {
                setMessage("Wachtwoord wijzigen mislukt");
                console.error("Change password error: ", response.data.error);
                setIsChangingPassword(false);
            }
        } catch (error) {
            console.error("Change password error: ", error);
            setMessage("Wachtwoord wijzigen mislukt");
            setIsChangingPassword(false);
        }
    };

    return (
        <div>
            <div className="header">
                <span className="header-icon">▶</span>
                <h1 className="header-title">Bestelportaal</h1>
                <img src={LHlogo} alt="LH logo" className="LH-logo" />
            </div>
            <div className="login-container">
                <div className="login-form">
            <h3>Wachtwoord wijzigen</h3>
            
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Huidig wachtwoord:</label>
                    <input 
                        type="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                </div>
                <div>
                    <label>Het nieuwe wachtwoord moet minstens 8 karakters lang zijn, minstens 1 hoofdletter, 1 kleine letter, 1 cijfer en 1 speciaal karakter bevatten</label>
                    <label>Nieuw wachtwoord:</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        maxLength={255}
                        />
                </div>
                <div>
                    <label>Bevestig nieuw wachtwoord:</label>
                    <input
                        type="password"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        maxLength={255}
                        />
                </div>
                <button type="submit" disabled={isChangingPassword}>
                    {isChangingPassword ? (
                        <div className="button-content">
                            <div className="spinner"></div> Wachtwoord wijzigen...
                        </div>
                    ) : "Wijzig wachtwoord"}
                </button>
                <button type="button" onClick={() => navigate(-1)}>Terug</button>
                {message && <p>{message}</p>}
            </form>
            </div>
            </div>
        </div>
    );
};

export default RenderPasswordChange;