import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getPricingAgreementsForCustomer, getProductListForCustomer } from "../api/crmAPI";
import { submitOrder, getOrderHistory, getLastInvoice, submitRecurringOrder, authenticatePassword} from "../api/salesAPI";
import { handleLogin, renderLoginForm } from "./loginPortal";
import RenderRecurringOrderScreen from "./recurringOrderScreen";
import RenderWelcomePage from "./WelcomePage/welcomePage";
import "./salesPortal.css";
import { renderProductList } from "./ProductPage/productList";
import { renderShoppingCart } from "./ProductPage/shoppingCart";
import RenderOrderConfirmation from "./OrderConfirmation/OrderConfirmation";
import LHlogo from "./LH.png";

const SalesPortal = () => {
    const navigate = useNavigate();
    const [customerName, setCustomerName] = useState("");
    const [password, setPassword] = useState("");
    const [pricingAgreements, setPricingAgreements] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [message, setMessage] = useState("");
    const [loggedIn, setLoggedIn] = useState(false); // Track whether the user is logged in
    const [cart, setCart] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedDeliveryDate, setSelectedDeliveryDate] = useState("");
    const [availableDeliveryDates, setAvailableDeliveryDates] = useState([]);
    const [orderConfirmation, setOrderConfirmation] = useState(false);
    const [recurringOrderDisplay, setRecurringOrderDisplay] = useState(false);
    const [deliveryAddresses, setDeliveryAddresses] = useState([]);
    const [orderComments, setOrderComments] = useState("");
    const [customerInvoice, setCustomerInvoice] = useState("");
    const [confirmationMessage, setConfirmationMessage] = useState(false);
    const [confirmationMessageText, setConfirmationMessageText] = useState("");
    const [orderHistory, setOrderHistory] = useState([]);
    const [transportationCosts, setTransportationCosts] = useState(0);
    const [safetyCosts, setSafetyCosts] = useState(0);
    const SESSION_TIMEOUT_DURATION = 1000 * 60 * 60; // 60 minutes
    const [isSubmitting, setisSubmitting] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [isOrderHistoryLoading, setIsOrderHistoryLoading] = useState(false);
    const [showWelcomePage, setShowWelcomePage] = useState(false);
    const [selectedAddressID, setSelectedAddressID] = useState("");
    const [sortedProducts, setSortedProducts] = useState([]);
    const [customerInvoiceMandatory, setCustomerInvoiceMandatory] = useState(false);

    const fetchPricingAgreements = (customerName) => {
        if (customerName) {
            getPricingAgreementsForCustomer(customerName)
                .then((response) => {
                    setPricingAgreements(response.data.PricingAgreements);
                    setDeliveryAddresses(response.data.DeliveryAddresses);
                    setCustomerInvoiceMandatory(response.data.CustomerInvoiceMandatory);
                    // Automatically select the address if there is only one
                    if (response.data.DeliveryAddresses.length === 1) {
                        setSelectedAddressID(response.data.DeliveryAddresses[0].AddressID);
                    };
                    setTransportationCosts(response.data.TransportationCosts);
                    setSafetyCosts(response.data.SafetyCosts);
                })
                .catch((error) => {
                    console.error("Error fetching pricing agreements:", error);
                });
        }
    };

    const fetchProductListForCustomer = (customerName) => {
        if (customerName) {
            getProductListForCustomer(customerName)
                .then((response) => {
                    setSortedProducts(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching product list:", error);
                });
        }
    };

    const initialQuantities = {};
    pricingAgreements.forEach((agreement) => {
        initialQuantities[agreement.ProductID] = 0;
    });


    const handleQuantityChange = (productId, quantity) => {
        // Update quantities in the state when the user selects a quantity
        setQuantities({ ...quantities, [productId]: quantity });
    };

    // Calculate the total quantity in the cart
    const totalQuantityInCart = cart.reduce((total, item) => total + item.quantity, 0);

    const fetchOrderHistory = (customerName) => {
        if (customerName) {
            setIsOrderHistoryLoading(true);
            getOrderHistory(customerName)
                .then((response) => {
                    setOrderHistory(response.data);
                    setIsOrderHistoryLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching order history:", error);
                    setIsOrderHistoryLoading(false);
                })

        }
    };

    const handleRepeatOrder = async (order) => {
        try {
            const updatedCart = [...cart];

            for (const detail of order.OrderDetails) {
                const productId = detail.ProductID;
                const quantity = detail.SaleQuantity;
                const product = pricingAgreements.find((agreement) => agreement.ProductID === productId);

                const existingProductIndex = updatedCart.findIndex((item) => item.productId === productId);

                if (existingProductIndex !== -1) {
                    // If the product is already in the cart, update its quantity
                    updatedCart[existingProductIndex].quantity += quantity;
                } else {
                    // If the product is not in the cart, add it
                    updatedCart.push({
                        productId,
                        quantity,
                        unitPrice: product.Price,
                    });
                }
            }

            // Update the cart and total price in the state
            setCart(updatedCart);
            updateTotalPrice(updatedCart);

            // Reset the quantities to 0
            setQuantities(initialQuantities);

            setShowWelcomePage(false);
            window.scrollTo(0, 0);
        } catch (error) {
            console.error("Error handling repeated order:", error);
        } 
    };

    const handleUpdateQuantity = async (productId, newQuantity) => {
        if (newQuantity < 0) {
            return;
        }
        try {
            // Calucate the total quantity in the cart
            const totalQuantityInCart = cart.reduce((total, item) => {
                if (item.productId === productId) {
                    return total + item.quantity;
                }
                return total;
            }, newQuantity);

            const updatedCart = [...cart];
            const productIndex = updatedCart.findIndex((item) => item.productId === productId);

            if (productIndex !== -1) {
                updatedCart[productIndex].quantity = newQuantity;
                setCart(updatedCart);
                updateTotalPrice(updatedCart);
            }
        } catch (error) {
            console.error("Error updating quantity:", error);
        }
    };

    const handleRemoveFromCart = (productId) => {
        const updatedCart = cart.filter((item) => item.productId !== productId);
        setCart(updatedCart);
        updateTotalPrice(updatedCart);
    };

    const updateTotalPrice = (cartItems) => {
        const updatedTotalPrice = cartItems.reduce(
            (total, item) => total + item.quantity * item.unitPrice,
            0
        );
        setTotalPrice(updatedTotalPrice);
    }

    const handleAddtoCart = async (productId) => {
        const quantity = quantities[productId] || 0;
        const product = pricingAgreements.find((agreement) => agreement.ProductID === productId);
        try {
            
            if (quantity > 0) {
                // Create a copy of the current cart and update it
                const updatedCart = [...cart];
                const existingProductIndex = updatedCart.findIndex((item) => item.productId === productId);

                if (existingProductIndex !== -1) {
                    // If the product is already in the cart, update its quantity
                    updatedCart[existingProductIndex].quantity += quantity;
                } else {
                    // If the product is not in the cart, add it
                    updatedCart.push({
                        productId,
                        quantity,
                        unitPrice: product.Price,
                    });
                }

                // Calculate the total price
                const updatedTotalPrice = updatedCart.reduce((total, item) => total + item.quantity * item.unitPrice, 0);

                // Update the cart and total price in the state
                setCart(updatedCart);
                setTotalPrice(updatedTotalPrice);

                // Check if adding this quantity would exceed stock quantity
                const totalQuantityInCart = updatedCart.reduce((total, item) => {
                    if (item.productId === productId) {
                        return total + item.quantity;
                    }
                    return total;
                }, quantity);

                // Reset the quantity to 0
                setQuantities({ ...quantities, [productId]: 0 });
            }
        } catch (error) {
            console.error("Error adding to cart:", error);
        }
    };


    const handleSubmitOrder = async () => {
        if (!selectedAddressID) {
            alert("U heeft nog geen bezorgadres opgegeven.");
            return;
        }

        setisSubmitting(true);

        // Create an array to store the products with their quantities
        const productsWithQuantities = [];

        // Loop through the cart and populate the productsWIthQuantities array
        cart.forEach((item) => {
            const productId = item.productId
            const quantity = item.quantity;
            const unitPrice = item.unitPrice;

            if (quantity > 0) {
                productsWithQuantities.push({
                    ProductID: productId,
                    SaleQuantity: quantity,
                    UnitPrice: unitPrice,
                });
            }
        });
        
        //Collect deliveryAddress data
        // const selectedAddress = deliveryAddresses.find((address) => address.selected);
        // console.log(selectedAddress);

        // if (!selectedDeliveryAddressID) {
        //     setMessage("U heeft nog geen bezorgadres opgegeven.");
        //     return;
        // }

        // Get the selected delivery date
        const formattedDeliveryDate = selectedDeliveryDate.toLocaleDateString('nl-NL');
        const formattedDeliveryDateParts = formattedDeliveryDate.split('-');
        // Get the selected delivery date in the right format for the SQL injection
        const formattedDeliveryDateSQL = `${formattedDeliveryDateParts[2]}-${formattedDeliveryDateParts[1]}-${formattedDeliveryDateParts[0]}`

        // count from the last invoicenumber in the database
        let lastInvoiceNumber = "";
        try {
            const response = await getLastInvoice();
            lastInvoiceNumber = response.data.lastInvoice;
        } catch (error) {
            console.error("Error fetching last invoice number:", error);
        }

        // Calculate the next invoice number
        const numericPart = parseInt(lastInvoiceNumber.slice(4));
        const defaultInvoiceNumber = `LHP-${(numericPart + 1).toString().padStart(6, '0')}`;

        const order = {
            CustomerName: customerName,
            InvoiceNumber: defaultInvoiceNumber,
            CustomerInvoice : customerInvoice,
            TransportationCosts: transportationCosts,
            SafetyCosts: safetyCosts,
            Comment: orderComments,
            SaleType: 'PortalSale',
            products: productsWithQuantities,
            SelectedDeliveryAddress: selectedAddressID,
            PreferredDeliveryDate: formattedDeliveryDate,
            PreferredDeliveryDateSQL: formattedDeliveryDateSQL,
        };

        submitOrder(order)
            .then((response) => {
                // Handle order submission success
                if (response.data && response.data.message) {
                    setConfirmationMessageText("Bedankt voor uw bestelling. U ontvangt een bevestiging per email. Kunt u geen email vinden? Controleer dan uw spambox.");

                } else {
                    // This is probably obsolete because if the API does not return a message, the catch block will be executed
                    setConfirmationMessageText("Er is een fout opgetreden bij het plaatsen van uw bestelling. Probeer het later nog eens.");
                }
                setConfirmationMessage(true);
                setOrderConfirmation(false);
                setSelectedProducts([]);
                setisSubmitting(false);
            })
            .catch((error) => {
                console.error("Error submitting order:", error);
                setConfirmationMessageText("Er is een fout opgetreden bij het plaatsen van uw bestelling. Probeer het later nog eens.");
                setConfirmationMessage(true);
                setOrderConfirmation(false);
                setSelectedProducts([]);
                setisSubmitting(false);
            });


    };

    useEffect(() => {
        //Generate a list of available delivery dates for the next 6 months
        const today = new Date();
        const next6Months = [...Array(6).keys()].map((month) => {
            const date = new Date(today);
            date.setMonth(today.getMonth() + month);
            return date;
        });

        // Filter out dates that are today, in the past, or on Saturdays and Sundays
        const filteredDates = next6Months.filter(
            (date) =>
                date.toDateString() !== today.toDateString() && // Not today
                date > today && // Not in the past
                date.getDay() !== 0 && // Not Sunday (0)
                date.getDay() !== 6 // Not Saturday (6)
        );

        setAvailableDeliveryDates(filteredDates);
    }, []);

    const handleDeliveryDateChange = (date) => {
        setSelectedDeliveryDate(date);
    };

    const handleBackButtonClick = () => {
        // Set orderconfirmation to false to navigate back to the product list
        setOrderConfirmation(false);
        window.scrollTo(0, 0);

        if (deliveryAddresses.length > 1) {
            setSelectedAddressID("");
        }
    };

    const handleLogout = () => {
        setLoggedIn(false);
        setCustomerName("");
        setPassword("");
        setMessage("");
        setOrderConfirmation(false);
        setConfirmationMessage(false);
        // setPricingAgreements([]);
        setCart([]);
        // setTotalPrice(0);
        setSelectedDeliveryDate("");
        // setAvailableDeliveryDates([]);
        // setRecurringOrderDisplay(false);
        // setDeliveryAddresses([]);
        setOrderComments("");
        setCustomerInvoice("");
        setOrderHistory([]);
        // setOrderHistoryCurrentPage(1);
        setSelectedAddressID("");
        if (process.env.NODE_ENV === 'development') {
            window.location.href = 'http://localhost:3000/';
        } else {
            window.location.href = 'https://www.bestel-lastechniekholland.nl/';
        }
    };

    const handleShowPasswordChange = () => {
        navigate(`/wachtwoord-wijzigen/${customerName}`);
    };

    useEffect(() => {
        if (!loggedIn) {
            return;
        }
        let timeout;

        const handleUserActivity = () => {
            clearTimeout(timeout); // Clear the timeout on user activity
            timeout = setTimeout(() => {
                handleLogout();
            }, SESSION_TIMEOUT_DURATION);
        };

        window.addEventListener("mousemove", handleUserActivity);
        window.addEventListener("mousedown", handleUserActivity);
        window.addEventListener("keypress", handleUserActivity);
        window.addEventListener("scroll", handleUserActivity);

        timeout = setTimeout(() => {
            handleLogout();
        }, SESSION_TIMEOUT_DURATION);

        return () => {
            window.removeEventListener("mousemove", handleUserActivity);
            window.removeEventListener("mousedown", handleUserActivity);
            window.removeEventListener("keypress", handleUserActivity);
            window.removeEventListener("scroll", handleUserActivity);
            clearTimeout(timeout);
        };
    }, [loggedIn, SESSION_TIMEOUT_DURATION]);

    return (
        <div>
            <div className="header">
                <span className="header-icon">▶</span>
                <h1 className="header-title">Bestelportaal</h1>
                <img src={LHlogo} alt="LH logo" className="LH-logo"/>
            </div>
    
            {/* Welcome message and logout button container */}
            {loggedIn && (
                <div className="welcome-container">
                    <div className="welcome-message">
                        <p>Welkom {customerName}</p>
                    </div>
                    <div className="button-group">
                        {!confirmationMessage && <span className="cart-quantity">Aantal cilinders in Winkelwagen: {totalQuantityInCart}</span>}
                        <button className="change-password-button" onClick={handleShowPasswordChange}>Wachtwoord wijzigen</button>
                        <button className="logout-button" onClick={handleLogout}>Uitloggen</button>
                    </div>
                </div>
            )}
    
            {/* Render login form if not logged in */}
            {!loggedIn && renderLoginForm(
                customerName,
                password,
                setCustomerName,
                setPassword,
                handleLogin,
                message,
                setLoggedIn,
                setMessage,
                fetchPricingAgreements,
                fetchOrderHistory,
                fetchProductListForCustomer,
                authenticatePassword,
                isLoggingIn,
                setIsLoggingIn,
                setShowWelcomePage
            )}

            {/* Render welcome page if showWelcomePage is true */}
            {loggedIn && showWelcomePage && <RenderWelcomePage 
                setShowWelcomePage={setShowWelcomePage}
                orderHistory={orderHistory}
                handleRepeatOrder={handleRepeatOrder}
                isOrderHistoryLoading={isOrderHistoryLoading}
                />}
    
            {/* Render order confirmation screen if logged in and order confirmation is true */}
            {loggedIn && !showWelcomePage && orderConfirmation && <RenderOrderConfirmation
                cart={cart}
                pricingAgreements={pricingAgreements}
                transportationCosts={transportationCosts}
                safetyCosts={safetyCosts}
                deliveryAddresses={deliveryAddresses}
                customerInvoice={customerInvoice}
                orderComments={orderComments}
                handleSubmitOrder={handleSubmitOrder}
                selectedDeliveryDate={selectedDeliveryDate}
                totalPrice={totalPrice}
                setCustomerInvoice={setCustomerInvoice}
                setOrderComments={setOrderComments}
                handleBackButtonClick={handleBackButtonClick}
                isSubmitting={isSubmitting}
                setSelectedAddressID={setSelectedAddressID}
                customerInvoiceMandatory={customerInvoiceMandatory}
            />}
    
            {/* Render a confirmation message */}
            {confirmationMessage && (
                <div>
                    <p className="confirmation-message">{confirmationMessageText}</p>
                </div>
            )}
    
            {/* Render the Recurring Order screen when recurringOrderDisplay is set to true */}
            {recurringOrderDisplay && !showWelcomePage && <RenderRecurringOrderScreen
                customerName={customerName}
                pricingAgreements={pricingAgreements}
                submitRecurringOrder={submitRecurringOrder}
            />}
    
            {/* Render product list, cart and order button if not submitted */}
            {loggedIn && !orderConfirmation && !confirmationMessage && !recurringOrderDisplay
            && !showWelcomePage && (
                <div>
                    {pricingAgreements && pricingAgreements.length > 0 && (
                        <>
                            {renderProductList(
                                customerName,
                                sortedProducts,
                                quantities,
                                handleQuantityChange,
                                handleAddtoCart
                            )}
                            {cart.length > 0 && renderShoppingCart(
                                pricingAgreements,
                                cart,
                                handleUpdateQuantity,
                                handleRemoveFromCart,
                                totalPrice,
                                selectedDeliveryDate,
                                handleDeliveryDateChange,
                                setOrderConfirmation,
                            )}
                        </>
                    )}
                    <button className="smallbutton" onClick={() => {
                        setShowWelcomePage(true);
                        window.scrollTo(0, 0);
                    }}
                    style={{marginLeft: '10px'}}
                    >
                        Terug naar de welkomstpagina
                    </button>
                </div>
            )}
        </div>
    );
};

export default SalesPortal;
