import{ apiService, apiServiceAWS, dynamicApiService } from './apiService';

export const addSale = (data) => {
    return apiService.post('/sales/add', data);
};

export const submitOrder = (data) => {
    return apiServiceAWS.post('/salesportal/submitorder', data);
};

export const submitRecurringOrder = (data) => {
    return apiService.post('/sales/submitrecurringorder', data);
};

export const getSales = () => {
    return apiService.get('/sales/salesdata');
};

export const getOrderHistory = (customerName) => {
    return apiServiceAWS.get(`/salesportal/getorderhistorycustomer/${customerName}`);
};

export const getLastInvoice = () => {
    return apiServiceAWS.get('/salesportal/getlastinvoice');
};

export const updateActualDeliveryDate = (orderID, data) => {
    return apiService.put(`/sales/actualdelivery/${orderID}`, data);
};

export const confirmOrder = (orderID) => {
    return apiService.put(`/sales/confirmorder/${orderID}`);
};

export const cancelOrder = (orderID) => {
    return apiService.put(`/sales/cancelorder/${orderID}`);
};

export const submitSale = (data) => {
    return apiService.post('sales/submitsale', data);
};

export const authenticatePassword = (data) => {
    return apiServiceAWS.post('/salesportal/authenticate', data);
};

export const changePassword = (data) => {
    return apiServiceAWS.post('/salesportal/changepassword', data);
};