import { BrowserRouter, Routes, Route } from "react-router-dom";
import SalesPortal from "./salesPortal/salesPortal";
import RenderPasswordChange from "./salesPortal/changePassword";
import { useEffect } from "react";
// import './App.css';

const App = () => {
  useEffect(() => {
    document.title = "Lastechniek Holland bestelportaal";
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SalesPortal />} />
        <Route path="/wachtwoord-wijzigen/:customerName" element={<RenderPasswordChange />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
