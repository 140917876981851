import './loginPortal.css';

export const handleLogin = async (
    customerName,
    password,
    setLoggedIn,
    setMessage,
    fetchPricingAgreements,
    fetchOrderHistory,
    fetchProductListForCustomer,
    authenticatePassword,
    setIsLoggingIn,
    setShowWelcomePage) => {
    if (!customerName || !password) {
        setMessage("Gebruikersnaam en wachtwoord moeten ingevuld zijn");
        return;
    }

    setIsLoggingIn(true);

    try {
        // Call the authenticatePassword API
        const response = await authenticatePassword({ customerName, password });

        if (response.data.message === "Authentication successful") {
            // If authentication is successful
            setLoggedIn(true);
            setMessage("Inlogggen gelukt");
            fetchPricingAgreements(customerName);
            fetchOrderHistory(customerName);
            fetchProductListForCustomer(customerName);
        } else {
            // If authentication is unsuccessful
            setLoggedIn(false);
            setMessage("Inloggen mislukt");
        } 
    } catch (error) {
            console.error("Login error: ", error);
            setMessage("Inloggen mislukt");
    } finally {
        setIsLoggingIn(false);
        setShowWelcomePage(true);
    }
};

export const renderLoginForm = (
    customerName,
    password,
    setCustomerName,
    setPassword,
    handleLogin,
    message,
    setLoggedIn,
    setMessage,
    fetchPricingAgreements,
    fetchOrderHistory,
    fetchProductListForCustomer,
    authenticatePassword,
    isLoggingIn,
    setIsLoggingIn,
    setShowWelcomePage
) => {
    const handleSubmit = (event) => {
        event.preventDefault();
        handleLogin(
            customerName,
            password,
            setLoggedIn,
            setMessage,
            fetchPricingAgreements,
            fetchOrderHistory,
            fetchProductListForCustomer,
            authenticatePassword,
            setIsLoggingIn,
            setShowWelcomePage);
    };
    return (
    <div className="login-container">
        <form className="login-form" onSubmit={handleSubmit}>
            <h3>Inloggen</h3>
            <div>
                <label>
                    Gebruikersnaam:
                    <input
                        type="text"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                    />
                </label>
            </div>
            <div>
                <label>
                    Wachtwoord:
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </label>
            </div>
            <div>
            <button type='submit' disabled={isLoggingIn}>
                {isLoggingIn ? (
                    <div className="button-content">
                        <div className="spinner"></div> Inloggen...
                    </div>
                ) : "Inloggen"}
            </button>
            </div>
            <p>{message}</p>
        </form>
    </div>
    );
};
