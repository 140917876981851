import React, {useState} from "react";
import DatePicker from "react-datepicker";

const RenderRecurringOrderScreen = ({
    customerName,
    pricingAgreements,
    submitRecurringOrder,
}) => {
    const [startDate, setStartDate] = useState(""); // start date for the recurring order
    const [endDate, setEndDate] = useState(""); // End date for recurring order
    const [recurrenceInterval, setRecurrenceInterval] = useState("monthly"); //default interval
    const [intervalValue, setIntervalValue] = useState(1); // Default interval value

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleReccurenceIntervalChange = (e) => {
        setRecurrenceInterval(e.target.value);
    };

    const handleIntervalValueChange = (e) =>{
        setIntervalValue(e.target.value);
    };

    const handleAddRecurringOrder = async () => {
        // Create a recurring order object with the selected data
        const recurringOrder = {
            customerName: customerName,
            StartDate: startDate,
            EndDate: endDate,
            RecurrenceInterval: recurrenceInterval,
            IntervalValue: intervalValue,
        };

        // Send the recurring order to the server to be saved in the database
        try {
            const response = await submitRecurringOrder(recurringOrder);
        } catch (error) {
            console.error("error adding recurring order:", error);
        }
    };

    // Filter pricing agreements to exclude thouse without a defined price
    const filteredPricingAgreements = pricingAgreements.filter(
        (agreement) => agreement.Price !== null
    );

    return (
        <div>
            <h3>Herhaalbestelling</h3>
            <div>
                <label>Begindatum:</label>
                <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    placeholderText="Selecteer een ingangsdatum"
                />
            </div>
            <div>
                <label>(optioneel) Einddatum:</label>
                <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    placeholderText="Selecteer een einddatum"
                />
            </div>
            <div>
                <label>Frequentie</label>
                <select value={recurrenceInterval} onChange={handleReccurenceIntervalChange}>
                    <option value="daily">Dagelijks</option>
                    <option value="weekly">Wekelijks</option>
                    <option value="monthly">Maandelijks</option>
                </select>
            </div>
            <div>
                <label>Frequentie:</label>
                <input
                    type="number"
                    value={intervalValue}
                    onChange={handleIntervalValueChange}
                />
            </div>
            <button onClick={handleAddRecurringOrder}>Voeg herhaalbestelling toe</button>
            <h3>Producten</h3>
            <p>U ziet hieronder de actuele prijsafspraken van uw producten. Deze kunnen op dagbasis verschillen.</p>
            <ul>
                {filteredPricingAgreements?.map((agreement) => (
                    <li key={agreement.ProductID}>
                        {agreement.ProductName} - Prijs: € {(agreement.Price).toFixed(2)}
                    </li>
                ))}
            </ul>
        </div>
    )
};

export default RenderRecurringOrderScreen;