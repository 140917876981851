import './productList.css';

export const renderProductList = (customerName, sortedProducts, quantities, handleQuantityChange, handleAddToCart) => {
    const decrementQuantity = (productId) => {
        const currentQuantity = quantities[productId] || 0;
        handleQuantityChange(productId, Math.max(currentQuantity -1, 0));
    };
    
    const incrementQuantity = (productId) => {
        const currentQuantity = quantities[productId] || 0;
        handleQuantityChange(productId, Math.max(currentQuantity +1, 0));
    };

    const getImageForProduct = (productName) => {
        try {
            const firstWord = productName.split(' ')[0].toLowerCase();
            return require(`./productImages/${firstWord}.jpg`)
        } catch (error) {
        return require('./productImages/default.jpg');
        }
    };
        
    return (
        <div className="table-container-productlist">
            <h3>Productenlijst</h3>
            <div>
            <table>
                <thead>
                    <tr>
                        <th>Product</th>
                        <th>Productnaam</th>
                        <th>Artikelnummer</th>
                        <th style={{minWidth: '115px'}}>Hoeveelheid</th>
                        <th>Toevoegen aan winkelwagen</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedProducts?.map((product) => {
                        const productImage = getImageForProduct(product.ProductName);
                        return (
                            <tr key={product.ProductID}>
                                <td>
                                    <img src={productImage} alt={product.ProductName} style={{width: '100px', height: '100px'}} />
                                </td>
                                <td>{product.ProductName}</td>
                                <td>{product.ProductNumber}</td>
                                <td>
                                    <button className='smallbutton' onClick={() => decrementQuantity(product.ProductID)}>-</button>
                                    <input
                                        type="text"
                                        value={quantities[product.ProductID] || ""}
                                        onChange={(e) =>
                                            handleQuantityChange(
                                                product.ProductID,
                                                parseInt(e.target.value)
                                            )
                                        }
                                        style={{maxWidth: '60px', textAlign: 'center'}}
                                    />
                                    <button className='smallbutton' onClick={() => incrementQuantity(product.ProductID)}>+</button>
                                </td>
                                <td>
                                    <button className='button' onClick={() => handleAddToCart(product.ProductID)}>
                                        Voeg toe
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div>
                {/* <p>Heeft u bepaalde producten regelmatig nodig? Klik hieronder om een terugkerende bestelling door te geven:</p>
                <button onClick={handleButtonRecurringOrder}>Herhaalbestelling</button> */}
            </div>
            </div>
        </div>
    );
};
