import React, { useEffect, useState } from "react";
import "./orderConfirmation.css";

const RenderOrderConfirmation = (props) => {
    const {
        cart,
        pricingAgreements,
        transportationCosts,
        safetyCosts,
        deliveryAddresses,
        customerInvoice,
        orderComments,
        handleSubmitOrder,
        selectedDeliveryDate,
        totalPrice,
        setCustomerInvoice,
        setOrderComments,
        handleBackButtonClick,
        isSubmitting,
        setSelectedAddressID,
        customerInvoiceMandatory,
    } = props;

    const activeDeliveryAddresses = deliveryAddresses.filter((address) => address.IsActive === "TRUE");

    // Initialize selectedAddress based on the number of delivery addresses
    const [selectedAddress, setSelectedAddress] = useState(activeDeliveryAddresses.length === 1 ? 0 : null);

    const handleAddressSelect = (index) => {
        if (activeDeliveryAddresses.length > 1) {
            setSelectedAddress(index);
            setSelectedAddressID(deliveryAddresses[index].AddressID); // Use this to inform parent component
        }
    };    

    const formattedDeliveryDate = selectedDeliveryDate.toLocaleDateString('nl-NL');

    const validateAndSubmit = () => {
        if (customerInvoiceMandatory === 1 && !customerInvoice) {
            alert("Vul een inkoopordernummer in.");
            return;
        }
        handleSubmitOrder();
    }
    
    return (
        <div className="table-container-orderconfirmation">
            <h3>Overzicht</h3>
            <h4>Winkelwagen</h4>
            <table>
                <thead>
                    <tr>
                        <th>Productnaam</th>
                        <th>Hoeveelheid</th>
                    </tr>
                </thead>
                <tbody>
                    {cart?.map((item) => {
                        const product = pricingAgreements.find(
                            (agreement) => agreement.ProductID === item.productId
                        );
                        return (
                            <tr key={item.productId}>
                                <td>{product.ProductName}</td>
                                <td>{item.quantity}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <h4 className="header-inline">Bezorgadres:</h4>
            {activeDeliveryAddresses.length > 1 && (
                "Er zijn meerdere bezorgadressen bekend. Klik op een adres om te selecteren."
            )}
            {activeDeliveryAddresses?.map((address, index) => (
                <div key={index}
                     className={`address-card ${selectedAddress === index ? "selected" : ""} ${deliveryAddresses.length === 1 ? "non-clickable" : ""}`}
                     onClick={() => handleAddressSelect(index)}>
                        {activeDeliveryAddresses.length > 1 && selectedAddress === index && (
                        <span className="selected-label">Geselecteerd</span>
                        )}
                    <div className="address-content">
                        {address.StreetName} {address.HouseNumber}
                    </div>
                    <div className="address-details">
                        {address.City} {address.ZipCode}
                    </div>
                </div>
            ))}

            <h4 className="header-inline">Gewenste bezorgdatum:</h4>
            <p className="paragraph-inline">{formattedDeliveryDate}</p>
            <h4>
                {customerInvoiceMandatory === 1 ? "Inkoopordernummer:" : "Inkoopordernummer (optioneel):"}
                <br />
                <input
                    type="text"
                    className="input-text"
                    value={customerInvoice}
                    onChange={(e) => setCustomerInvoice(e.target.value)}
                    maxLength={50}
                />
            </h4>
            <div>
                <h4>Opmerkingen bij de bestelling:
                    <br />
                    <textarea
                        className="input-text"
                        rows="4"
                        cols="50"
                        value={orderComments}
                        onChange={(e) => setOrderComments(e.target.value)}
                    />
                </h4>
            </div>
            <button className="smallbutton" onClick={validateAndSubmit} disabled={isSubmitting}>
                {isSubmitting ? (
                    <div className="button-content">
                        <div className="spinner"></div> Bestelling plaatsen...
                    </div>
                ) : "Bestelling plaatsen"}
            </button>
            <button  onClick={handleBackButtonClick}>Vorige pagina</button>
        </div>
    );
};

export default RenderOrderConfirmation;
